import React from 'react';
import {
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
  PageNavigation,
  Paragraph,
  PlatformTab,
  Section,
} from '../../../../components';

const WebTab = () => {
  return (
    <PlatformTab>
      <PageNavigation links={['Durations', 'Easings']} />

      <Section title="Durations">
        <Paragraph>The larger the element, the slower the duration.</Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Fast</DataTableValueBlock>
              <DataTableValueBlock>0.15s</DataTableValueBlock>
              <DataTableCodeBlock>
                var(--animation-duration-fast)
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Default</DataTableValueBlock>
              <DataTableValueBlock>0.3s</DataTableValueBlock>
              <DataTableCodeBlock>
                var(--animation-duration-default)
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Slow</DataTableValueBlock>
              <DataTableValueBlock>0.45s</DataTableValueBlock>
              <DataTableCodeBlock>
                var(--animation-duration-slow)
              </DataTableCodeBlock>
            </DataTableRow>
          </DataTableBody>
        </DataTable>
      </Section>

      <Section title="Easings">
        <Paragraph>
          Use the name of the easing to guide when you should use it.
        </Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Static</DataTableValueBlock>
              <DataTableValueBlock>linear</DataTableValueBlock>
              <DataTableCodeBlock>
                var(--animation-easing-static)
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Moving</DataTableValueBlock>
              <DataTableValueBlock>ease-in-out</DataTableValueBlock>
              <DataTableCodeBlock>
                var(--animation-easing-moving)
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Entering</DataTableValueBlock>
              <DataTableValueBlock>
                cubic-bezier(0.175, 0.885, 0.32, 1.275)
              </DataTableValueBlock>
              <DataTableCodeBlock>
                var(--animation-easing-entering)
              </DataTableCodeBlock>
            </DataTableRow>
            <DataTableRow layout="thirds">
              <DataTableValueBlock>Exiting</DataTableValueBlock>
              <DataTableValueBlock>
                cubic-bezier(0.6, -0.28, 0.735, 0.045)
              </DataTableValueBlock>
              <DataTableCodeBlock>
                var(--animation-easing-exiting)
              </DataTableCodeBlock>
            </DataTableRow>
          </DataTableBody>
        </DataTable>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
